<template>
<Page id="users" title="Deltagare">
      <Loader v-if="loading" loadingtext="Hämtar deltagare..."></Loader>
      <div v-else>
            <div v-if="users.length > 0" class="text-center font-italic">
                  <p>I {{tournamentname}} deltar dessa {{users.length}} tipsare!</p>
            </div>
            <div v-if="users.length > 0">
                  <div class="text-center font-italic my-3">
                        Totalt {{Paying + PayingVIP}} betalande användare varav {{PayingVIP}} VIP-användare som kan se andras tips!
                  </div>
                  <div class="d-flex justify-content-center flex-wrap">
                        <User v-for="user in users" :key="user.id" :user="user"></User>
                  </div>
            </div>
            <Alert v-else variant="info" >Just nu finns inga deltagare</Alert>
      </div>
</Page>
</template>

<script>
const Alert = () => import("@/components/miscellaneous/Alert");
const Page = () => import("@/components/common/Page");
const Loader = () => import("@/components/miscellaneous/Loader");
const User = () => import("@/components/users/User");

export default {
      name: "Users",
      components: {
            Alert,
            Page,
            Loader,
            User
      },
      data() {
            return {};
      },
      metaInfo: {
            title: "Deltagare",
            meta: [{
                  name: "description",
                  content: "Dessa tipsare deltar i turneringen!",
            }, ],
      },
      computed: {
            loading() {
                  return this.$store.getters["tournamentsg/tournamentsloading"] || this.$store.getters["tournamentsg/usertournamentsloading"];
            },
            Paying() {
                  return this.users.filter((item) => {
                        return item.access == 20
                  }).length;
            },
            PayingVIP() {
                  return this.users.filter((item) => {
                        return item.access == 21 || item.access == 30
                  }).length;
            },
            users() {
                  var items = this.$store.getters["tournamentsg/tournamentusers"];
                  if (!items) items = [];
                  return items;
            },
            tournamentname() {
                  return this.$store.getters["tournamentsg/tournament"].name;
            }
      },
      methods: {},
      mounted() {
            this.$store.dispatch("tournamentsg/getTournamentUsers");
      },
};
</script>
